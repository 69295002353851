exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cast-js": () => import("./../../../src/pages/cast.js" /* webpackChunkName: "component---src-pages-cast-js" */),
  "component---src-pages-dvd-js": () => import("./../../../src/pages/dvd.js" /* webpackChunkName: "component---src-pages-dvd-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-story-js": () => import("./../../../src/pages/story.js" /* webpackChunkName: "component---src-pages-story-js" */),
  "component---src-pages-theater-js": () => import("./../../../src/pages/theater.js" /* webpackChunkName: "component---src-pages-theater-js" */),
  "component---src-pages-trailer-js": () => import("./../../../src/pages/trailer.js" /* webpackChunkName: "component---src-pages-trailer-js" */)
}

