import * as React from "react";

const initialState = {
  isMobile: false,
  opened: false,
  duration: 0.35
};

const NavContext = React.createContext();

const useNavContext = () => {
  return React.useContext(NavContext);
};

const navReducer = (state, action) => {
  switch (action.type) {
    case 'OPENED':
      if (state.opened !== action.value) {
        state = Object.assign({}, state, {
          opened: action.value
        });
      }
      break;
    case 'IS_MOBILE':
      if (state.isMobile !== action.value) {
        state = Object.assign({}, state, {
          isMobile: action.value
        });
      }
      break;
    case 'DURATION':
      state = Object.assign({}, state, {
        duration: action.value
      });
      break;
    default:
      break;
  }
  return state;
};

const NavProvider = ({ children }) => {
  const [navState, navAction] = React.useReducer(navReducer, initialState);
  const openedAction = React.useCallback((opened) => navAction({type: 'OPENED', value: opened}), []);
  const isMobileAction = React.useCallback((isMobile) => navAction({type: 'IS_MOBILE', value: isMobile}), []);
  const setDuration = React.useCallback((duration) => navAction({type: 'DURATION', value: duration}), []);
  return (
    <NavContext.Provider value={{ navState, openedAction, isMobileAction, setDuration }}>
      {children}
    </NavContext.Provider>
  );
};

export { useNavContext, NavProvider };
